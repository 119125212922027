import React from 'react';
import { jsx, Box, Flex } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { serializers } from '../../utils/blockMarks';
import { ImageFullWidth, ThemeHeader } from '../../components';
import CTALink from '../CTALink';
import useElementOnScreen from '../../hooks/useElementOnScreen';

const introStyles = {
  base: {},
  content: {
    flexDirection: 'column',
    variant: 'styles.flexCenter',
  },
  img: {
    maxHeight: 725,
  },
  text: {
    py: [5, 8],
    px: 2,
    color: 'textInverted',
    textAlign: 'center',
    maxWidth: 570,
  },
  copy: {
    variant: 'styles.p',
    mb: 3,
    pb: 1,
  },
};

const introStylesTwo = {
  base: {
    variant: 'styles.wrapperSpace',
  },
  wrapper: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      height: '50%',
      left: 0,
      right: 0,
      zIndex: 0,
      backgroundColor: 'background',
    },
  },
  imgWrap: {
    maxWidth: 920,
    mx: 'auto',
    px: [3, null, 0],
    position: 'relative',
    zIndex: 1,
  },
  content: {
    justifyContent: 'center',
    pt: [7, null, 10],
    pb: [5, null, 7],
  },
  text: {
    maxWidth: ['66%', null, 672],
    textAlign: 'center',
    color: 'text',
    mx: 'auto',
    pt: [null, null, 4],
  },
};

const Intro = ({ pageBody }) => {
  // Animation refrences, Animate when element visible on screen
  const [IntroImage1Ref, isIntroImage1Visible] = useElementOnScreen();
  const [IntroHeading1Ref, isIntroHeading1Visible] = useElementOnScreen();
  const [IntroImage2Ref, isIntroImage2Visible] = useElementOnScreen();
  const [IntroHeading2Ref, isIntroHeading2Visible] = useElementOnScreen();
  const [IntroText2Ref, isIntroText2Visible] = useElementOnScreen();
  const [IntroCTA2Ref, isIntroCTA2Visible] = useElementOnScreen();  

  const { _rawCta: cta, _rawText: text, _rawCarouselImages: carouselImages, bgColor, title, styleType } = pageBody;
  return styleType === '1' ? (
    <Box sx={introStylesTwo.base}>
      <Box sx={introStylesTwo.inner} style={{ backgroundColor: bgColor }}>
        <Box sx={introStylesTwo.wrapper}>
          <Box
            ref={IntroImage1Ref}
            sx={{ ...introStylesTwo.imgWrap, ...{ transition: 'opacity 1s ease', opacity: isIntroImage1Visible ? 1 : 0 } }}
          >
            <ImageFullWidth src={carouselImages.asset._ref} alt="" />
          </Box>
        </Box>
        <Flex sx={introStylesTwo.content}>
          <Box
            ref={IntroHeading1Ref}
            sx={{ ...introStylesTwo.text, ...{ transition: 'opacity 1s ease 0.25s', opacity: isIntroHeading1Visible ? 1 : 0 } }}
          >
            <ThemeHeader.H1 isGradient isShimmer={isIntroHeading1Visible}>
              {title}
            </ThemeHeader.H1>
          </Box>
        </Flex>
      </Box>
    </Box>
  ) : (
    <Box sx={introStyles.base} style={{ backgroundColor: bgColor }}>
      <Box ref={IntroImage2Ref} sx={{ transition: 'opacity 1s ease', opacity: isIntroImage2Visible ? 1 : 0 }}>
        <ImageFullWidth src={carouselImages.asset._ref} style={introStyles.img} alt="" />
      </Box>
      <Flex sx={introStyles.content}>
        <Box sx={introStyles.text}>
          <Box ref={IntroHeading2Ref} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isIntroHeading2Visible ? 1 : 0 }}>
            <ThemeHeader.H2>{title}</ThemeHeader.H2>
          </Box>
          <Box ref={IntroText2Ref} sx={{ ...introStyles.copy, ...{ transition: 'opacity 1s ease 0.5s', opacity: isIntroText2Visible ? 1 : 0 } }}>
            <BlockContent blocks={text} serializers={serializers} />
          </Box>
          {cta?.link?.showLink ? (
            <Box ref={IntroCTA2Ref} sx={{ transition: 'opacity 1s ease 0.75s', opacity: isIntroCTA2Visible ? 1 : 0 }}>
              <CTALink linkData={cta} />
            </Box>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
};

export default Intro;
